/**
=========================================================
* Otis Admin PRO - v2.0.2
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  labels: ["Facebook", "Direct", "Organic", "Referral"],
  datasets: {
    label: "Projects",
    backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
    data: [15, 20, 12, 60],
  },
};
