/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
import { Box, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";

function EmptyDatesView({ OnAddDate }) {
  return (
    <Box textAlign="center" py={5}>
      <CalendarMonthIcon sx={{ width: "80px", height: "80px", color: "grey.400" }} />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Nothing here yet
      </Typography>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        Add dates to finish the step
      </Typography>
      <MDButton variant="contained" color="info" size="medium" sx={{ mt: 3 }} onClick={() => OnAddDate("add")}>
        <Icon>add</Icon>&nbsp;Add new date
      </MDButton>
    </Box>
  );
}
// Define PropTypes
EmptyDatesView.propTypes = {
  OnAddDate: PropTypes.func.isRequired, // Validate that OnAddDate is a required function
};
export default EmptyDatesView;
