/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slider from "@mui/material/Slider";



export default function MDSlider({ max, min, step, defaultValue,  value, onChange, onChangeCommitted,primaryColor, thumbColor }) {
    const theme = createTheme({
        components: {
          MuiSlider: {
            styleOverrides: {
              root: {
                color: primaryColor || "#1A73E8", // Changes the slider's primary color
              },
              thumb: {
                backgroundColor: thumbColor|| "#1A73E8", // Thumb (circle) color
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "0px 0px 0px 8px rgba(36, 125, 234, 0.16)", // Hover/focus effect
                },
              },
              track: {
                backgroundColor: primaryColor || "#1A73E8", // Track color
              },
              rail: {
                backgroundColor: "#e0e0e0", // Rail color (inactive track)
              },
            },
          },
        },
      });
  return (
    <ThemeProvider theme={theme}>
      <Slider
        value={value} // Controlled value
        onChange={onChange} // Callback for value changes
        onChangeCommitted={onChangeCommitted}
        defaultValue={defaultValue}
        min={min}
        max={max}
        step={step}
      />
    </ThemeProvider>
  );
}
