/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";

function TrajectoriesAnalytics() {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDButton
        color="secondary"
        onClick={() => {
          navigate("/trajectories/dates");
        }}
      >
        Start
      </MDButton>
    </DashboardLayout>
  );
}

export default TrajectoriesAnalytics;
