/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
=========================================================
* Otis Admin PRO - v2.0.2
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import SignInBasic from "layouts/authentication/sign-in/basic";
// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import CreateEvent from "layouts/events/create";
import Events from "layouts/events/show";
import MapCreateEvent from "layouts/events/create/compoenents/MapCreateEvent";
import TrajectoriesAnalytics from "layouts/trajectories";
import Dates from "layouts/trajectories/steps/Dates";
import Times from "layouts/trajectories/steps/Times";
import Link from "layouts/trajectories/steps/Link";
import Summary from "layouts/trajectories/steps/Summary";
import MapPreviewEvent from "layouts/events/create/compoenents/MapPreviewEvent";
import Results from "layouts/trajectories/results/Results";

const routes = [
  {
    type: "collapse",
    name: "Brooklyn Alice",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "Sales",
        key: "sales",
        route: "/dashboards/sales",
        component: <Sales />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="medium">public</Icon>,
    collapse: [
      {
        name: "Show Events",
        key: "showEvents",
        route: "/events/show",
        component: <Events />,
      },
      {
        name: "create Events",
        key: "createEvents",
        route: "/events/create",
        component: <CreateEvent />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Trajectories",
    key: "trajectories",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Trajectories Analytics",
        key: "trajectoriesAnalytics",
        route: "/trajectories",
        component: <TrajectoriesAnalytics />,
      },
    ],
  },
  {
    route: "/events/create/map-create-event",
    component: <MapCreateEvent />,
  },
  {
    route: "/trajectories/dates",
    component: <Dates />,
  },
  {
    route: "/trajectories/times",
    component: <Times />,
  },
  {
    route: "/trajectories/link",
    component: <Link />,
  },
  {
    route: "/trajectories/summary",
    component: <Summary />,
  },
  {
    route: "/trajectories/result",
    component: <Results />,
  },
  {
    route: "/events/map-preview-event",
    component: <MapPreviewEvent />,
  },
];

export default routes;
