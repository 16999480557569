/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable prefer-destructuring */

import * as React from "react";
import { useState, useEffect } from "react";
import { Map, NavigationControl, Popup, useControl } from "react-map-gl";
import { GeoJsonLayer,TextLayer  } from "deck.gl";
import { MapboxOverlay as DeckOverlay } from "@deck.gl/mapbox";
import { CircularProgress, Backdrop } from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import SelectionService from "../../../services/SelectionService";




const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const API_TOKEN = process.env.REACT_APP_MAP_MATCHING_API_TOKEN;
const API_URL = process.env.REACT_APP_MAP_MATCHING_API_URL;

const INITIAL_VIEW_STATE = {
  latitude: 40.6310772242652,
  longitude: 22.953091034889667,
  zoom: 14,
  bearing: 0,
  pitch: 50,
  controller: true,

};
const MAP_STYLE = "mapbox://styles/mapbox/dark-v11";

function DeckGLOverlay(props) {
  const overlay = useControl(() => new DeckOverlay(props));
  overlay.setProps(props);
  return null;
}
const getGradientColor = (percent) => {
  if(percent === null) return [238, 43, 122]
  // Gradient stops
  const gradientColors = [
    [213, 216, 232], // 0%
    [195, 200, 223], // 10%
    [178, 184, 214], // 20%
    [161, 168, 205], // 30%
    [144, 152, 196], // 40%
    [127, 136, 187], // 50%
    [110, 120, 178], // 60%
    [92, 104, 169],  // 70%
    [75, 88, 160],   // 80%
    [58, 72, 151],   // 90%
    [42, 57, 144]    // 100%
];

  // Ensure the percentage is between 0 and 100
  percent = Math.max(0, Math.min(percent, 100));

  // Calculate the two stops to interpolate between
  const lowerIndex = Math.floor(percent / 10);
  const upperIndex = Math.ceil(percent / 10);

  // Get the colors for the stops
  const lowerColor = gradientColors[lowerIndex];
  const upperColor = gradientColors[upperIndex];

  // Calculate the interpolation factor
  const factor = (percent % 10) / 10;

  // Interpolate between the two colors
  const interpolatedColor = lowerColor.map((lc, i) =>
    Math.round(lc + factor * (upperColor[i] - lc))
  );

  return interpolatedColor;
};
const calculateOffsetPosition = (coordinates, offsetx,offsety) => {
  if (coordinates.length < 2) return coordinates[0];

  // Use the first two points to calculate the direction
  const [lon1, lat1] = coordinates[0];
  const [lon2, lat2] = coordinates[1];

  // Calculate a perpendicular offset vector
  const dx = lon2 - lon1;
  const dy = lat2 - lat1;
  const length = Math.sqrt(dx * dx + dy * dy);
  const offsetX = (-dy / length) * offsetx; // Perpendicular x
  const offsetY = (dx / length) * offsety; // Perpendicular y

  // Return the shifted coordinate
  return [lon1 + offsetX, lat1 + offsetY];
};
function MapViewResult({ incomingPercent, valueType }) {
  const [selected, setSelected] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [data, setData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(INITIAL_VIEW_STATE.zoom);
  const [cursor, setCursor] = useState('auto');
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(5);

  useEffect(() => {
    var wayId = SelectionService.getSelectedLink().properties.wayId;
    var fromDate = dayjs(SelectionService.getDates()[SelectionService.getSelectedDate()].startDate[0]).format("DD-MM-YYYY");
    var toDate =  dayjs(SelectionService.getDates()[SelectionService.getSelectedDate()].startDate[1]).format("DD-MM-YYYY");
    var fromTime = "00:00";
    var toTime = "00:00";
    var selectedTime = SelectionService.getTimes()[SelectionService.getSelectedTime()]

    if(!selectedTime.allDay){
      fromTime = dayjs(selectedTime.from[0]).format("HH:mm:ss");
      toTime = dayjs(selectedTime.to[0]).format("HH:mm:ss");
    }
    console.log(`${API_URL}/GetWayStats2/${wayId}?from=${fromDate} ${fromTime}&to=${toDate} ${toTime}&threshold=${incomingPercent}`);
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await axios.get(`${API_URL}/GetWayStats2/${wayId}?from=${fromDate} ${fromTime}&to=${toDate} ${toTime}&threshold=${incomingPercent}`, {
          headers: {
            'X-API-Key': API_TOKEN
          }
        });
   
       const filteredData = response.data.features.filter((x) => x.properties.wayId === wayId);
        setData(response.data);
        setSelected(filteredData);
        console.log(filteredData);
        const maxCountFeature = response.data.features.reduce((max, feature) => {
          const currentCount = feature.properties.count || 0; // Default to 0 if count is undefined
          return currentCount > (max.properties.count || 0) ? feature : max;
        }, { properties: { count: 0 } });
      } catch (error) {
        console.error('Error fetching data:', error);
      }finally {
        setLoading(false); // Stop loading
      }
    };
    console.log(incomingPercent !== percent)
    if(incomingPercent !== percent){
    setPercent(percent);
    fetchData();
    }
  }, [incomingPercent, valueType]);


  const handleZoom = (event) => {
    const newZoomLevel = event.viewState.zoom;
    setZoomLevel(newZoomLevel);
  };
  const handleHover = (info) => {
    if (info && info.object) {
      setHoverInfo(info);
    } else {
      setHoverInfo(null);
    }
  };


  var layers = data?[
    new GeoJsonLayer({
      id: 'path-layer',
      data: data,
      filled: true,
      stroked: true,
      lineWidthMinPixels: zoomLevel > 15 ? 4 : 2,
      getLineWidth: zoomLevel > 15 ? 8 : 1,
      getPointRadius: zoomLevel > 15 ? 15 : 10,
      getLineColor: (feature) => {
        return getGradientColor(feature.properties.percentage)
      },
      pickable: true,
      autoHighlight: true,
      highlightColor: [0, 0, 0, 80],
      onHover: handleHover     
    }),
    selected
      ? new GeoJsonLayer({
          id: "selected-layer",
          data: {
            type: "FeatureCollection",
            features: selected,
          },
          filled: true,
          stroked: true,
          lineWidthMinPixels: 4,
          getLineWidth: 8,
          getLineColor: [238, 43, 122],
        })
      : null,
  ]:[];
  return (
    <>
    <Backdrop open={loading} style={{ zIndex: 999, color: "#fff" }}>
    <CircularProgress color="inherit" />
  </Backdrop>
 
    <Map
      initialViewState={INITIAL_VIEW_STATE}
      mapStyle={MAP_STYLE}
      cursor={cursor}
      mapboxAccessToken={MAPBOX_TOKEN}
      controller={true}
      onZoom={handleZoom}
      onViewStateChange={handleZoom}>
      {hoverInfo  && zoomLevel > 15 &&  hoverInfo.object && (
        <Popup
          longitude={hoverInfo.coordinate[0]}
          latitude={hoverInfo.coordinate[1]}
          closeButton={false}
          anchor="bottom"
          style={{ zIndex: 10 }}
        >
          <div>
            <strong>{hoverInfo.object.properties.name}</strong> ({hoverInfo.object.properties.wayId})
            <br />
            {valueType === "Percent" 
              ? `Percent: ${hoverInfo.object.properties.percentage || 'N/A'}` 
              : `Count: ${hoverInfo.object.properties.count || 'N/A'}`}
          </div>
        </Popup>
      )}
      <DeckGLOverlay layers={layers} />
      <NavigationControl position="top-left" />
    </Map>
    </>
  );
}

export default MapViewResult;