// src/layouts/events/create/components/EventForm.js
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";

function EventForm() {
  const navigate = useNavigate();
  const location = useLocation();
  // Default form data if location.state or formData is not provided
  const initialFormData = {
    incidentType: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    locations: [],
    fromJunction: "",
    toJunction: "",
    trafficImpact: "",
    comments: "",
  };
  // Use the provided formData or fallback to the initial default
  const [formData, setFormData] = useState(location.state?.formData || initialFormData);

  // Update formData if new data is passed via location.state
  useEffect(() => {
    if (location.state?.formData) {
      console.log("Received formData:", location.state.formData.locations);
      setFormData(location.state.formData);
    }
  }, [location.state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOpenMap = () => {
    console.log("Navigating to MapCreateEvent with formData:", formData);
    navigate("/events/create/map-create-event", { state: { formData } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Submitted Form Data:", formData);
    navigate("/events/map-preview-event", { state: { formData } }); // Pass form data to the preview page
    // Send formData to backend API or process it
  };

  return (
    <Card id="event-form" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">
          Κέντρο Ενιαίας Καταγραφής & Πληροφόρησης Προγραμματισμένων Εκδηλώσεων & Έκτακτων Συμβάντων
        </MDTypography>
      </MDBox>
      <MDBox compoent="form" pb={3} px={3}>
        <MDTypography
          compoent="label"
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          1.Τύπος συμβάντος
        </MDTypography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              name="incidentType"
              value={formData.incidentType}
              options={[
                "Σύγκρουση οχημάτων",
                "Ακινητοποιημένο όχημα",
                "Οδικά έργα",
                "Απαγόρευση κυκλοφορίας",
                "Απαγόρευση στάθμευσης",
                "Πορεία / Διαδήλωση",
              ]}
              onChange={(e, value) =>
                handleInputChange({ target: { name: "incidentType", value } })
              }
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="startDate"
              label="2.Ημερομηνία έναρξης συμβάντος"
              placeholder="YYYY-MM-DD"
              type="date"
              value={formData.startDate}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="startTime"
              label="3.Ώρα έναρξης συμβάντος"
              placeholder="HH:MM"
              type="time"
              value={formData.startTime}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="endDate"
              label="4.Ημερομηνία λήξης συμβάντος"
              placeholder="YYYY-MM-DD"
              type="date"
              value={formData.endDate}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="endTime"
              label="5.Ώρα λήξης συμβάντος"
              placeholder="HH:MM"
              type="time"
              value={formData.endTime}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDButton variant="gradient" color="secondary" onClick={handleOpenMap}>
              6. Θέση συμβάντος
            </MDButton>
            {
              // location.state.formData.locations.geometry
              // formData.locations.length
              formData.locations.geometry && (
                <MDBox mt={2}>
                  <MDTypography variant="button" fontWeight="light" color="success">
                    Geometry successfully selected!
                  </MDTypography>
                </MDBox>
              )
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="fromJunction"
              label="7. Από κόμβο"
              placeholder="Από κόμβο"
              value={formData.fromJunction}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              name="toJunction"
              label="8. Μέχρι κόμβο"
              placeholder="Μέχρι κόμβο"
              value={formData.toJunction}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormField
              name="trafficImpact"
              label="9. Εκτίμηση επιπτώσεων στην κυκλοφορία"
              placeholder="Εκτίμηση επιπτώσεων στην κυκλοφορία"
              value={formData.trafficImpact}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                10. Σχόλια παρατηρήσεις&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDEditor
              value={formData.comments}
              onChange={(value) => handleInputChange({ target: { name: "comments", value } })}
            />
          </Grid>
          <Grid mt={3} item xs={12} sm={12}>
            <MDButton variant="gradient" color="secondary" onClick={handleSubmit}>
              Submit
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox p={2}>
        <MDBox>
          <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
            <MDButton
              variant="gradient"
              color="dark"
              // onClick={!isLastStep ? handleNext : undefined}
            >
              Submit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox> */}
    </Card>
  );
}

export default EventForm;
