import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import EventForm from "./compoenents/EventForm";
// import MapCreateEvent from "./compoenents/MapCreateEvent";

function CreateEvent() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <h1>This is create event</h1>
        {/* <MapCreateEvent /> */}
        <EventForm />
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateEvent;
