/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Box, Grid, Typography, Button, Card, CardContent, CardHeader, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import SelectionService from "../../../services/SelectionService";
import StepperNav from "../StepperNav";

function Summary() {
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setDates(SelectionService.getDates());
    setTimes(SelectionService.getTimes());
    setSelectedDateIndex(SelectionService.getSelectedDate());
    setSelectedTimeIndex(SelectionService.getSelectedTime());
    setSelectedLink(SelectionService.getSelectedLink());
  }, []);

  const handleProceedToNextStep = () => {
    navigate("/trajectories/result"); // Proceed to the next step
  };

  const handleProceedToPreviousStep = () => {
    navigate("/trajectories/link"); // Go back to the previous step
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container direction="row" sx={{ alignItems: "stretch", height: "85vh" }}>
        <Box sx={{ width: "30%", height: "85vh", display: "flex", flexDirection: "column" }}>
          <Grid container direction="column" sx={{ flex: 1 }} >
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
          <Card sx={{ mb: 3 }}>
              <CardHeader title="Title" />
              <CardContent>
              <TextField id="outlined-basic"  variant="outlined"         
                multiline
                maxRows={2}
                fullWidth
                value={name}
                onChange={(e) => {setName(e.target.value); SelectionService.setReportName(e.target.value);}}

            />
              </CardContent>
            </Card>

          {/* Dates Card */}
          {dates.length > 0 && (
            <Card sx={{ mb: 3 }}>
              <CardHeader title="Dates" />
              <CardContent>
                <Typography variant="body1">{dates[0].title}</Typography>
                <Typography variant="body2">
                  {dayjs(dates[0].startDate).format("DD/MM/YYYY")} -{" "}
                  {dayjs(dates[0].endDate).format("DD/MM/YYYY")}
                </Typography>
              </CardContent>
            </Card>
          )}

          {/* Times Card */}
          {times.length > 0 && (
            <Card sx={{ mb: 3 }}>
              <CardHeader title="Times" />
              <CardContent>
                <Typography variant="body1">
                  {times[0].allDay
                    ? "All day"
                    : `${dayjs(times[0].from).format("HH:mm")} - ${dayjs(times[0].to).format("HH:mm")}`}
                </Typography>
              </CardContent>
            </Card>
          )}

          {/* Selected Link Card */}
          <Card sx={{ mb: 3 }}>
            <CardHeader title="Selected Link" />
            <CardContent>
              {selectedLink ? (
                <Box>
                  <Typography>Way ID: {selectedLink.properties.wayId} </Typography>
                  <Typography>Name: {selectedLink.properties.name} </Typography>
                  </Box>
              ) : (
                <Typography>No link selected</Typography>
              )}
            </CardContent>
          </Card>
          </Grid>
          {/* Navigation Buttons */}
          <Grid item 
            xs="auto"
            sx={{
            mt: "auto", // Pushes the grid to the bottom of the container
            textAlign: "right", // Aligns buttons to the right
            }}>
         <Grid container justifyContent="flex-end" spacing={2}>
         <Grid item>
            <MDButton 
                variant="outlined"
                color="info"
                size="medium" onClick={handleProceedToPreviousStep}>
              Back
            </MDButton>
            </Grid>
            <Grid item>
            <MDButton 
                variant="contained"
                color="info"
                size="medium" onClick={handleProceedToNextStep}>
              Result
            </MDButton>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Box>
      <Box sx={{ flex: 1 }}>
        <StepperNav />
      </Box>
    </Grid>
    </DashboardLayout>
  );
}

export default Summary;
