/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { Box, Typography, FormControlLabel, Checkbox, TextField } from "@mui/material";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";

function AddTimeView({ onSave }) {
  const [newTime, setNewTime] = useState({ from: null, to: null, allDay: false });

  const handleSave = () => {
    if (newTime.allDay || (newTime.from && newTime.to)) {
      onSave(newTime);
    } else {
      alert("Please fill in all required fields");
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" textAlign="center" sx={{ mb: 2 }}>
        Add Time Range
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={newTime.allDay}
            onChange={(e) => setNewTime({ ...newTime, allDay: e.target.checked })}
          />
        }
        label="All day"
      />
      {!newTime.allDay && (
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Flatpickr
            value={newTime.from}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i", // Format as HH:mm
              time_24hr: true,
            }}
            onChange={(selectedDates) => {
              setNewTime({ ...newTime, from: selectedDates }); // Update the selected time
            }}
          />
          <MDDatePicker
            label="Start Date"
            value={newTime.to}
            onChange={(selectedDates) => {
              setNewTime({ ...newTime, to: selectedDates }); // Update the selected time
            }}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i", // Format as HH:mm
              time_24hr: true,
            }}
            renderInput={(params) => (
              <TextField placeholder="End Date" {...params} sx={{ mr: 1, mb: 2 }} />
            )}
          />
        </Box>
      )}
      <Box mt={3} sx={{ display: "flex", justifyContent: "space-between" }}>
      <MDButton variant="outlined" color="error" size="medium" sx={{ mt: 3 }} onClick={() => onSave(null)}>
          Cancel
        </MDButton>
      <MDButton variant="contained" color="info" size="medium" sx={{ mt: 3 }} onClick={handleSave}>
        Save Date
      </MDButton>
      </Box>
    </Box>
  );
}

export default AddTimeView;
