class SelectionService {
  constructor() {
    this.selectedDateIndex = null;
    this.dates = []; // Store the list of dates

    this.selectedTimeIndex = null;
    this.times = []; // Store the list of times

    this.selectedLink = null; // Store the selected link

    this.reportName = ""; // Store the selected link
  }

  // Date Methods
  setSelectedDate(index) {
    this.selectedDateIndex = index;
  }

  getSelectedDate() {
    return this.selectedDateIndex;
  }

  setDates(dates) {
    this.dates = dates;
  }

  getDates() {
    return this.dates;
  }

  addDate(date) {
    this.dates.push(date);
  }

  deleteDate(index) {
    this.dates = this.dates.filter((_, i) => i !== index);
    if (this.selectedDateIndex === index) this.selectedDateIndex = null;
  }

  // Time Methods
  setSelectedTime(index) {
    this.selectedTimeIndex = index;
  }

  getSelectedTime() {
    return this.selectedTimeIndex;
  }

  setTimes(times) {
    this.times = times;
  }

  getTimes() {
    return this.times;
  }

  addTime(time) {
    this.times.push(time);
  }

  deleteTime(index) {
    this.times = this.times.filter((_, i) => i !== index);
    if (this.selectedTimeIndex === index) this.selectedTimeIndex = null;
  }

  // Link Methods
  setSelectedLink(link) {
    this.selectedLink = link;
  }

  getSelectedLink() {
    return this.selectedLink;
  }

  // name Methods
  setReportName(name) {
    this.reportName = name;
  }

  getReportName() {
    return this.reportName;
  }
}

export default new SelectionService();
