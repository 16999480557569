/**
=========================================================
* Deck Map 
=========================================================
*/
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import thessData from "../data/thess_geojson.json";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function MapCreateEvent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData } = location.state || {}; // Retrieve formData passed from EventForm
  const [eventPosition, setEventPosition] = useState(null); // State for storing event position
  const drawRef = useRef(null);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [hasDrawn, setHasDrawn] = useState(false); // State to track first draw

  useEffect(() => {
    // Log incoming formData for debugging
    console.log("Received formData in MapCreateEvent:", formData);
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [22.9527, 40.6253],
      zoom: 12,
    });

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        line_string: true,
        polygon: false,
        trash: true,
      },
    });

    if (!mapRef.current.hasControl(drawRef.current)) {
      mapRef.current.addControl(drawRef.current);
    }

    mapRef.current.on("load", () => {
      // convert all routes to GeoJSON

      // Add each GeoJSON object to the map
      // Add the source
      console.log("data to sourec MapPreview", thessData);
      mapRef.current.addSource("route", {
        type: "geojson",
        data: thessData,
      });

      // Add the layer
      if (!mapRef.current.getLayer("route")) {
        mapRef.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#888",
            "line-width": 4,
            "line-opacity": 0.5,
          },
        });
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      let hoveredPolygonId = null;

      // Update popup content and higlight the hovered state
      mapRef.current.on("mousemove", "route", (e) => {
        const { features } = e; // Destructure `features` from the event
        if (e.features && e.features.length > 0) {
          const {
            properties: { name },
          } = features[0]; // Access camelCase `PathName`

          popup.setLngLat(e.lngLat).setHTML(`<strong>${name}</strong>`).addTo(mapRef.current);
          // Reset previous hover state
          if (hoveredPolygonId !== null) {
            mapRef.current.setFeatureState(
              { source: "route", id: hoveredPolygonId },
              { hover: false }
            );
          }
          // Set hover state for the current polygon
          hoveredPolygonId = e.features[0].id;
          mapRef.current.setFeatureState(
            { source: "route", id: hoveredPolygonId },
            { hover: true }
          );
          // Change cursor style
          mapRef.current.getCanvas().style.cursor = "pointer";
        }
      });

      // Remove hover effect and popup when the cursor leaves the layer
      mapRef.current.on("mouseleave", "route", () => {
        if (hoveredPolygonId !== null) {
          mapRef.current.setFeatureState(
            { source: "route", id: hoveredPolygonId },
            { hover: false }
          );
        }
        hoveredPolygonId = null;
        popup.remove();
        mapRef.current.getCanvas().style.cursor = "";
      });

      mapRef.current.on("draw.create", (e) => {
        const drawnFeature = e.features[0]; // The drawn geometry

        if (drawnFeature.geometry.type === "Point") {
          // Set the hasDrawn flag to true after the first draw
          setHasDrawn(true);
          // Query features at the drawn point's location
          const features = mapRef.current.queryRenderedFeatures(
            mapRef.current.project(drawnFeature.geometry.coordinates), // Convert to screen coordinates
            { layers: ["route"] } // Query the route layer
          );
          if (features.length > 0) {
            const matchedFeature = features[0]; // Take the first matching feature
            const eventPositionDraw = {
              geometry: drawnFeature.geometry, // Geometry of the drawn feature
              PathID: matchedFeature.properties.path_id || "Unknown ID",
              PathName: matchedFeature.properties.name || "Unknown Route",
            };

            setEventPosition(eventPositionDraw); // Update state
            console.log("eventPositionDraw Point", eventPositionDraw);
            //     console.log("Point is not on any route.");
          } else {
            console.log("Point is not on any route.");
          }
          // Set the hasDrawn flag to true after the first draw
          setHasDrawn(true);
        } else if (drawnFeature.geometry.type === "LineString") {
          // Handle LineString geometry
          const lineCoordinates = drawnFeature.geometry.coordinates; // from draw
          const matchingRoutes = []; // Store matched routes without duplicates

          console.log("lineCoordinates", lineCoordinates);

          lineCoordinates.forEach((coordinate) => {
            const features = mapRef.current.queryRenderedFeatures(
              mapRef.current.project(coordinate), // convert to screen coordinates
              { layer: ["route"] } // Query the route layer
            );

            if (features.length > 0) {
              features.forEach((feature) => {
                if (feature.layer.id === "route") {
                  const pathId = feature.properties.path_id;
                  // Check if PathID is already in matchingRoutes

                  // Check if PathID is already in matchingRoutes
                  const existingRoute = matchingRoutes.find((route) => route.PathID === pathId);

                  if (!existingRoute) {
                    console.log("if Id not exists", feature.properties);
                    const eventPositionDraw = {
                      PathID: pathId || "Unknown ID",
                      PathName: feature.properties.name || "Unknown Route",
                      geometry: feature.geometry, // Store geometry of the matched route
                    };

                    console.log("New match found:", eventPositionDraw);
                    matchingRoutes.push(eventPositionDraw); // Add to matchingRoutes
                  } else {
                    console.log(`Merging geometry for PathID ${pathId}`);
                    // Merge geometries (append coordinates)
                    existingRoute.geometry.coordinates.push(...feature.geometry.coordinates);
                  }

                  // } else {
                  //     console.log("id exist");
                  // }
                }
              });
            } else {
              console.log(`Coordinate ${coordinate} does not intersect any route.`);
            }
          });

          if (matchingRoutes.length > 0) {
            console.log("Matching routes for LineString:", matchingRoutes);
            setEventPosition(matchingRoutes[0]);
          } else {
            console.log("LineString does not intersect any route.");
          }
          // Set the hasDrawn flag to true after the first draw
          setHasDrawn(true);
        } else {
          console.error(`Unsupported geometry type: ${drawnFeature.geometry.type}`);
        }
      });

      // Listen for draw.modechange to clear previous features
      mapRef.current.on("draw.modechange", () => {
        if (hasDrawn) {
          drawRef.current.deleteAll(); // Clear only if it's not the first draw
          console.log("Cleared previous features");
          setHasDrawn(false);
        }
      });
    });

    // clean up on component unmount
    return () => {
      if (mapRef.current) {
        if (mapRef.current.getLayer("route")) {
          mapRef.current.removeLayer("route");
        }
        if (mapRef.current.getSource("route")) {
          mapRef.current.removeSource("route");
        }
        if (drawRef.current) {
          mapRef.current.removeControl(drawRef.current);
        }
        mapRef.current.remove();
      }
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <MDTypography variant="h3">Map Create Event</MDTypography>
      </MDBox>
      <Card>
        <MDBox p={4}>
          <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {eventPosition ? (
                <div>
                  {eventPosition.message ? (
                    <p>{eventPosition.message}</p>
                  ) : (
                    <>
                      <p>
                        <strong>Path Name:</strong> {eventPosition.PathName}
                      </p>
                      <p>
                        <strong>Path ID:</strong> {eventPosition.PathID}
                      </p>
                      <p>
                        <strong>Coordinates:</strong>{" "}
                        {`[${eventPosition.geometry.coordinates[0]}, ${eventPosition.geometry.coordinates[1]}]`}
                      </p>
                    </>
                  )}
                </div>
              ) : (
                "Draw a point or line to display the event position."
              )}
            </MDTypography>
          </MDBox>
          <MDButton
            color="secondary"
            onClick={() => {
              if (eventPosition) {
                console.log("eventPosition", eventPosition);
                const updatedFormData = {
                  ...formData,
                  locations: eventPosition,
                };
                console.log("Updated FormData to Return:", updatedFormData);
                navigate("/events/create", { state: { formData: updatedFormData } });
                // navigate("/events/create", { state: { geometry: eventPosition } });
              }
            }}
          >
            Select Geometry
          </MDButton>
        </MDBox>
      </Card>

      <MDBox
        id="map-create-event"
        ref={mapContainerRef}
        sx={{ width: "100%", height: "70vh", mt: 3 }}
      />
    </DashboardLayout>
  );
}

export default MapCreateEvent;
