/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EmptyDatesView from "../dates/EmptyDatesView";
import AddDateView from "../dates/AddDateView";
import DatesListView from "../dates/DatesListView";
import SelectionService from "../../../services/SelectionService";
import StepperNav from "../StepperNav";

function Dates() {
  const [view, setView] = useState("empty"); // empty, add, list
  const [dates, setDates] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState(null); // Tracks selected date
  const navigate = useNavigate();

  useEffect(() => {
    const savedDates = SelectionService.getDates();
    const savedIndex = SelectionService.getSelectedDate();

    if (savedDates.length > 0) {
      setDates(savedDates);
      setView("list");
    }

    if (savedIndex !== null) setSelectedDateIndex(savedIndex);
  }, []);

  const handleAddDate = (value) => {
    console.log(`Button clicked with value: ${value}`); // Log the value passed from EmptyDatesView
    setView("add"); // Use the passed value to update the view
  };

  const handleSaveDate = (newDate) => {
    if (newDate === null) {
      console.log(newDate);
      if (dates.length > 0) {
        setView("list");
      } else {
        setView("empty");
      }
    } else {
      const updatedDates = [...dates, newDate];
      console.log(updatedDates);
      setDates(updatedDates);
      SelectionService.setDates(updatedDates); // Save list of dates
      setView("list");
    }
  };

  const handleEditDate = (index) => {
    // Edit logic
  };

  const handleDeleteDate = (index) => {
    SelectionService.deleteDate(index); // Delete from service
    const updatedDates = SelectionService.getDates(); // Get updated list
    setDates(updatedDates);

    if (updatedDates.length === 0) {
      setView("empty");
    }
  };

  const handleSelectDate = (index) => {
    setSelectedDateIndex(index); // Sets the selected date
    SelectionService.setSelectedDate(index);
  };

  const handleProceedToNextStep = () => {
    navigate("/trajectories/times"); // Navigate to the next step
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container direction="row" sx={{ alignItems: "stretch", height: "85vh" }}>
        <Box sx={{ width: "30%", height: "85vh", display: "flex", flexDirection: "column" }}>
          <Grid container direction="column" sx={{ flex: 1 }} >
          <Grid item xs={8} sx={{ flexGrow: 1 }}>
            {view === "empty" && <EmptyDatesView OnAddDate={handleAddDate} />}
            {view === "add" && <AddDateView onSave={handleSaveDate} />}
            {view === "list" && (
              <DatesListView
                dates={dates}
                selectedDateIndex={selectedDateIndex}
                onEdit={handleEditDate}
                onDelete={handleDeleteDate}
                onSelect={handleSelectDate}
                OnAddDate={handleAddDate}
              />
            )}
           </Grid>
           <Grid item 
            xs="auto"
            sx={{
            mt: "auto", // Pushes the grid to the bottom of the container
            textAlign: "right", // Aligns buttons to the right
            }}>
         <Grid container justifyContent="flex-end" spacing={2}>
         <Grid item>
              <MDButton
                variant="contained"
                color="info"
                size="medium"
                disabled={selectedDateIndex === null}
                onClick={handleProceedToNextStep}>
                Next
              </MDButton>
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flex: 1 }}>
          <StepperNav />
        </Box>
      </Grid>
    </DashboardLayout>
  );
}

export default Dates;
