/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { Grid, Typography, TextField } from "@mui/material";
import { useState } from "react";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";

function AddDateView({ onSave }) {
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSave = () => {
    if (title && startDate && endDate) {
      onSave({ title, startDate, endDate });
    } else {
      alert("Please fill in all fields");
    }
  };

  return (
    <Grid container spacing={3} p={3}>
      {/* Title */}
      <Grid item xs={12}>
        <Typography variant="h4" textAlign="center">
          Create a New Date
        </Typography>
      </Grid>

      {/* Title Input */}
      <Grid item xs={12}>
        <TextField
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
      </Grid>

      {/* Date Pickers */}
      <Grid item xs={12} sm={6}>
        <MDDatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(params) => <TextField placeholder="Start Date" {...params} fullWidth />}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MDDatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField placeholder="End Date" {...params} fullWidth />}
        />
      </Grid>

      {/* Buttons */}
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <MDButton
            variant="outlined"
            color="error"
            size="medium"
            onClick={() => onSave(null)}
          >
            Cancel
          </MDButton>
        </Grid>
        <Grid item>
          <MDButton
            variant="contained"
            color="info"
            size="medium"
            onClick={handleSave}
          >
            Save Date
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddDateView;
