import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

function MapPreviewEvent() {
  const location = useLocation();
  const { formData } = location.state || {};
  const mapContainerRef = useRef(null); // Reference for the map container
  const mapRef = useRef(null); // Reference to store the Mapbox map instance

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current, // The HTML element ID for the map
      style: "mapbox://styles/mapbox/streets-v12", // Map style
      center: [22.9527, 40.6253], // Map center coordinates
      zoom: 12, // Initial zoom level
    });

    // Optional: Add a handler for when the map loads
    mapRef.current.on("load", () => {
      console.log("Preview Page Map Loaded, Received Data:", formData);
      // Wrap the geometry in a valid GeoJSON feature
      const locationFeature = {
        type: "Feature",
        geometry: formData.locations.geometry,
        properties: {
          PathID: formData.locations.PathID,
          PathName: formData.locations.PathName,
        },
      };

      const geojsonData = {
        type: "FeatureCollection",
        features: [locationFeature],
      };

      console.log("data to sourec MapPreview", geojsonData);

      mapRef.current.addSource("previewData", {
        type: "geojson",
        data: geojsonData,
      });

      // Add the layer
      if (!mapRef.current.getLayer("preview-event")) {
        // Add the appropriate layer
        const locationType = formData.locations.geometry.type;
        if (locationType === "Point") {
          console.log("location type is point", geojsonData);
          mapRef.current.addLayer({
            id: "preview-event",
            type: "circle",
            source: "previewData",
            paint: {
              "circle-radius": 10,
              "circle-color": "#007cbf",
            },
          });
        } else if (locationType === "LineString") {
          console.log("location type is linestring");
          mapRef.current.addLayer({
            id: "preview-event",
            type: "line",
            source: "previewData",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#007cbf",
              "line-width": 2,
            },
          });
        }
      }
    });

    // Cleanup when component unmounts
    return () => {
      if (mapRef.current.getLayer("preview-event")) {
        mapRef.current.removeLayer("preview-event");
      }
      if (mapRef.current.getSource("preview-event")) {
        mapRef.current.removeSource("preview-event");
      }
      mapRef.current.remove();
    };
  }, []); // Re-run if formData changes (if necessary)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <h1>This is the Map Preview Page</h1>
      </MDBox>
      <MDBox
        id="map-preview-event"
        ref={mapContainerRef}
        sx={{ width: "100%", height: "70vh", mt: 3 }}
      />
    </DashboardLayout>
  );
}

export default MapPreviewEvent;
