/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDSlider from "components/MDSlider";
import MDButton from 'components/MDButton';
import { Box, Grid, Typography, Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MapViewResult from '../map/MapViewResult';
import SelectionService from "../../../services/SelectionService";
import GradientLegend from '../../../components/GradientLegend/GradientLegend';


function Results() {
  const [direction, setDirection] = useState('Outgoing');
  const [valueType, setValueType] = useState('Percent');
  const [minPercent, setMinPercent] = useState(4);
  const [percent, setPercent] = useState(4);
  const [name, setName] = useState("");
  useEffect(() => {
    setName(SelectionService.getReportName());

  }, []);
  const handleDirectionChange = (_, newDirection) => {
    if (newDirection !== null) {
      setDirection(newDirection);
    }
  };

  const handleValueTypeChange = (_, newValue) => {
    if (newValue !== null) {
      setValueType(newValue);
    }
  };

  return (
<DashboardLayout>
  <DashboardNavbar />
  <Grid container direction="row" sx={{ alignItems: "stretch", height: "85vh" }}>
    {/* Sidebar */}
    <Box sx={{ width: "30%", height: "85vh", display: "flex", flexDirection: "column" }}>
      <Grid container direction="column" sx={{ flex: 1 }}>
        <Grid item xs={8} sx={{ flexGrow: 1 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>{name}</Typography>
        <Card sx={{ mb: 2 }}>
          <CardHeader title="Filters" />
          <CardContent>
          <Typography variant="subtitle1" gutterBottom>Values</Typography>
          <ToggleButtonGroup
              value={valueType}
              exclusive
              onChange={handleValueTypeChange}
              fullWidth
              sx={{ mb: 2 }}
            >
              <ToggleButton value="Percent">Percent</ToggleButton>
              <ToggleButton value="Trips">Trips</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="subtitle1" gutterBottom>Cut off</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <MDSlider
              defaultValue={0}
              primaryColor="#1A73E8" // Custom primary color
              thumbColor="#1A73E8" // Custom thumb color
              value={minPercent}
              onChange={(e, newValue) => setMinPercent(newValue)}
              onChangeCommitted={(e, newValue) => setPercent(newValue)}
              min={0}
              max={15}
              step={1}
            />
            <Typography>{minPercent}</Typography>
            </Box>
          </CardContent>
        </Card>

        {/* <MDButton 
          variant="contained"
          color="info"
          size="medium" sx={{ mt: 3 }}>
          Download
        </MDButton> */}
        <GradientLegend/>
        </Grid>
        </Grid>
      </Box>
      
      <Grid container item sx={{ width: "70%", height: "85vh", padding: 0.5 }} direction="column">
      <Grid item sx={{ flex: 1 }}>
        <MapViewResult incomingPercent={percent} valueType={valueType} />
        </Grid>
    </Grid>
  </Grid>
</DashboardLayout>
  );
}

export default Results;